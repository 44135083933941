import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Seo from "../components/Seo";
import TopNav from "../components/TopNav";
import Footer from "../components/Footer";
import "../styles/apply.scss";

const ApplyPage = () => {
  const [embedLoaded, setEmbedLoaded] = useState(0);
  useEffect(() => {
    if (embedLoaded > 1) {
      // next/submit has been clicked
      window.scrollTo(0, 250);
    }
  }, [embedLoaded]);
  const onEmbedLoad = () => {
    setEmbedLoaded((prev) => prev + 1);
  };

  return (
    <>
      <Seo
        title="Application Form"
        description="Join us and together we can create a financial system that creates conditions for prosperity for everyone."
      />
      <TopNav />
      <main id="apply-page" className="mt-n8 pt-10 pb-10">
        <Container>
          <h1 className="text-center mb-8">Application Form</h1>
          <Row className="align-content-center justify-content-center text-center">
            <Col xs="12">
              <iframe
                id="google-form-embed"
                title="Application Form"
                className="google-form-embed"
                src="https://docs.google.com/forms/d/e/1FAIpQLSe5L8Ve2NTA6gc5pwtyTQHeNXMRz_DvYjIjnpiJjUzKJEs97A/viewform?embedded=true"
                width="640"
                height="1093"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
                onLoad={onEmbedLoad}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </iframe>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default ApplyPage;
